import MainErrorMCIcon from '@public/mc/main-error-mc.svg';

const MainErrorMC = () => (
  <div className="flex flex-col items-center justify-center max-md:my-10 md:my-20 px-4">
    <MainErrorMCIcon className="w-[120px] h-[120px] flex-shrink-0" />
    <div className="mt-7 text-white1 text-xl font-bold max-w-[380px] text-center">
      Scheduled Maintenance in Progress
    </div>
    <div className="mt-3 text-white5 max-w-[380px] text-center">
      We&apos;re working hard to improve your experience. Please check back shortly
    </div>
  </div>
);

export default MainErrorMC;
