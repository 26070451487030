/* eslint-disable @next/next/no-head-element */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable @next/next/no-page-custom-font */
import Link from 'next/link';
import { ReactNode } from 'react';
import { Open_Sans, DM_Mono } from 'next/font/google';

import Logo from '@public/mc/logo.svg';
import { ROUTES } from '@/constants/routes/default';
import HeaderLinks from '../../HeaderLinks';
import FooterMC from '../FooterMC';

import '../../../app/globals.css';
import './DefaultLayoutMC.css';

export const metadata = {
  title: 'Opportunities | McCarthy',
  description: 'Find the best opportunities at McCarthy',
};

interface LayoutProps {
  children: ReactNode;
}

const openSans = Open_Sans({
  subsets: ['latin'],
  weight: ['400', '700'],
  display: 'swap',
  variable: '--font-open-sans', // Define a CSS variable for Open Sans
});

const dmMono = DM_Mono({
  subsets: ['latin'],
  weight: ['400', '500'],
  display: 'swap',
  variable: '--font-dm-mono', // Define a CSS variable for DM Mono
});

const DefaultLayoutMC = async ({ children }: LayoutProps) => {
  return (
    <html lang="en" className={`${openSans.variable} ${dmMono.variable}`}>
      <head>
        <meta charSet="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
      </head>
      <body className="body-mc">
        <link rel="shortcut icon" href="/mc/favicon.ico" />
        <div className="h-full flex flex-col justify-between">
          <div className="flex flex-col grow">
            <header className="flex md:px-10 max-md:px-2 min-h-[122px] header-mc">
              <div className="max-w-[940px] w-full h-full flex justify-between items-center m-auto flex-wrap gap-4 md:pr-6 max-md:pr-1">
                <Link href={ROUTES.home}>
                  <Logo className="w-[250px] h-[30px] flex-shrink-0" />
                </Link>
                <HeaderLinks
                  listHeaderCn="px-3.5 py-2 text-white3"
                  dropdownClassNames={{
                    listCn: 'max-w-[200px] right-0 bg-cod-gray1 py-2 rounded-lg',
                    elementCn: 'text-sm text-white1 h-9 px-5 flex items-center hover:bg-mine-shaft',
                    rootCn: '',
                    iconCn:
                      'h-10 w-10 rounded-lg [&>svg>path]:!fill-zombie hover:bg-cod-gray1 [&>svg>path]:hover:!fill-white',
                    activeIconCn: 'bg-cod-gray1 [&>svg>path]:!fill-white',
                  }}
                  componentCn="flex gap-5 items-center"
                  linkCn="search-link-mc text-zombie no-underline capitalize text-sm font-bold default-transition hover:text-white hover:underline hover:decoration-2 hover:underline-offset-2"
                  skipSession
                />
              </div>
            </header>
            <main className="pt-20 px-4 grow main-mc container-mc w-full">{children}</main>
          </div>
          <FooterMC isAuthorized={false} />
        </div>
      </body>
    </html>
  );
};

export default DefaultLayoutMC;
