import { cn } from '@/helpers/common';
import MedicalBox from '@public/cn/homepage-header-1.svg';
import { ReactNode } from 'react';

const HeaderComponentCN = ({
  children,
  mainClassName,
  main2ClassName,
}: {
  children?: ReactNode;
  mainClassName?: string;
  main2ClassName?: string;
}) => {
  return (
    <div className={cn('w-full', mainClassName)}>
      <div className={cn('w-full bg-shakespeare', main2ClassName)}>
        <div className="pt-[60px] px-4 max-w-[1160px] mx-auto flex max-md:justify-center md:justify-between items-center gap-8">
          <MedicalBox className="max-md:hidden md:block w-[104px] h-[100px] flex-shrink-0" />
          {children}
          <MedicalBox className="max-md:hidden md:block w-[104px] h-[100px] flex-shrink-0 transform scale-x-[-1]" />
        </div>
      </div>
      <div className="relative rotate-180 -z-10 w-full h-20 homepage-header-background-cn" />
    </div>
  );
};

export default HeaderComponentCN;
