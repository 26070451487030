export const SUPPORT_EMAIL = 'jobs@davidcareers.com';

export const DEFAULT_LOADER_TEXTS = [
  'Checking to see if this job is still open for applications',
  'Compiling the most relevant details to help you make an informed decision',
  'If this role has closed, no worries — we’ll suggest similar, exciting opportunities that are still available!',
];

export const PROFILE_LOADER_TEXTS = [
  'Organizing your expertise for maximum impact',
  'Fine-tuning every detail for a standout resume',
];

export const STUB_DEFAULT_TEXTS = {
  mainText: 'No matching jobs found',
  subText: 'Please make sure your keywords are spelled correctly',
};
