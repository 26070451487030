import MainErrorCNIcon from '@public/cn/main-error-cn.svg';
import HeaderComponentCN from '../HeaderComponentCN';

const MainErrorCN = () => (
  <>
    <HeaderComponentCN main2ClassName="max-md:min-h-[100px] md:min-h-[196px]" />
    <div className="flex flex-col items-center justify-center my-20 px-4">
      <MainErrorCNIcon className="w-[106px] h-[106px] flex-shrink-0" />
      <p className="text-black1 mt-6 max-w-[420px] font-bold text-center uppercase">
        Down for Maintenance
      </p>
      <p className="text-black1 mt-4 max-w-[420px] text-center text-sm">
        We&apos;re upgrading our site to serve you better. Thanks for your understanding!
      </p>
    </div>
  </>
);

export default MainErrorCN;
