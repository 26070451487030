import { ROUTES } from '@/constants/routes/default';
import { cn } from '@/helpers/common';
import Logo from '@public/cn/logo.svg';
import Link from 'next/link';

const FooterCN = ({ isAuthorized }: { isAuthorized: boolean }) => {
  return (
    <footer className="flex md:px-10 max-md:px-4 py-4 min-h-[120px] border-t border-black3">
      <div className="max-w-[1080px] w-full h-full max-sm:block sm:flex justify-between items-center m-auto flex-wrap gap-4">
        <div>
          <Logo className="w-[140px] h-4 flex-shrink-0" />
          <p className="text-sm text-black1 mt-1.5">© All Rights Reserved</p>
        </div>

        <ul
          className={cn(
            'max-w-[450px] grid  gap-y-2 gap-x-[80px] list-disc marker:text-black1',
            'grid-cols-2 max-sm:gap-x-8 sm:gap-x-20 max-sm:ml-4 sm:ml-0 max-sm:mt-2 sm:mt-0',
          )}
        >
          <li key="home">
            <Link
              className="text-jelly-bean font-bold text-sm hover:text-black1 default-transition"
              href={ROUTES.home}
            >
              Home
            </Link>
          </li>
          <li key="terms">
            <Link
              className="text-jelly-bean font-bold text-sm hover:text-black1 default-transition"
              href={ROUTES.termsOfUse}
            >
              Terms of Use
            </Link>
          </li>
          {isAuthorized ? (
            <li key="account">
              <Link
                className="text-jelly-bean font-bold text-sm hover:text-black1 default-transition"
                href={ROUTES.profile}
              >
                Account
              </Link>
            </li>
          ) : (
            <li key="login">
              <Link
                className="text-jelly-bean font-bold text-sm hover:text-black1 default-transition"
                href={ROUTES.signin}
              >
                Login
              </Link>
            </li>
          )}
          <li key="private">
            <Link
              className="text-jelly-bean font-bold text-sm hover:text-black1 default-transition"
              href={ROUTES.privacyPolicy}
            >
              Privacy Policy
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default FooterCN;
