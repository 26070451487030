/* eslint-disable @next/next/no-img-element */
import { ROUTES } from '@/constants/routes/default';
import { getFullYear } from '@/helpers/date';
import Link from 'next/link';

const FooterMC = ({ isAuthorized }: { isAuthorized: boolean }) => {
  return (
    <footer className="flex flex-col">
      <div className="max-w-[940px] w-full h-full flex flex-wrap justify-between items-center mx-auto px-10 py-4 gap-4 bg-cod-gray1">
        <div className="text-sm text-white2">
          <p>McCarthy Search</p>
          <p className="mt-3">10736 Jefferson Blvd Suite 390,</p>
          <p className="mt-3">Culver City, CA 90230</p>
        </div>
        <div className="w-fit flex flex-wrap text-anakiwa gap-y-2 gap-x-10">
          <ul className="flex flex-col gap-3">
            <li>
              {isAuthorized ? (
                <Link className="text-sm block w-fit hover:underline" href={ROUTES.profile}>
                  Account
                </Link>
              ) : (
                <Link className="text-sm block w-fit hover:underline" href={ROUTES.signup}>
                  Register
                </Link>
              )}
            </li>
            <li>
              <Link className="text-sm block w-fit hover:underline" href={ROUTES.jobs}>
                Search Jobs
              </Link>
            </li>
          </ul>
          <ul className="flex flex-col gap-3">
            <li>
              <Link className="text-sm block w-fit hover:underline" href={ROUTES.privacyPolicy}>
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link className="text-sm block w-fit hover:underline" href={ROUTES.termsOfUse}>
                Terms of Use
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="max-w-[940px] w-full mx-auto bg-mine-shaft">
        <p className="py-3 text-center text-white2">
          Copyright &copy; McCarthy Search, {getFullYear()}
        </p>
      </div>
    </footer>
  );
};

export default FooterMC;
