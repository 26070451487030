'use client';
/* eslint-disable @next/next/no-img-element */
import React from 'react';
import HeaderLinks from '../HeaderLinks';
import IconSearch from '@public/icons/search-icon.svg';
import { ROUTES } from '@/constants/routes/default';
import Link from 'next/link';

const HeaderJC = ({ skipSession = false }: { skipSession?: boolean }) => {
  return (
    <header className="flex md:px-10 max-md:px-4 min-h-[76px] border-b border-gallery">
      <div className="max-w-[1160px] w-full h-full flex justify-between items-center m-auto flex-wrap gap-4">
        <Link href={ROUTES.home}>
          <img
            className="max-[400px]:w-[125px] min-[400px]:w-[250px] max-[400px]:h-[15px] min-[400px]:h-[30px]"
            width="250"
            height="30"
            src="https://assets.johnsonjobs.com/johnsonjobs/logo_v2.png"
            alt="Logo"
          />
        </Link>
        <HeaderLinks
          componentCn="flex gap-4 items-center"
          listHeaderCn="px-3.5 py-2 text-dove-gray"
          dropdownClassNames={{
            listCn: 'max-w-[200px] right-0 bg-white py-1.5 border border-mercury1 rounded-md',
            elementCn: 'text-sm h-[34px] px-3.5 flex items-center hover:bg-mercury1',
            rootCn: '',
            iconCn:
              'h-9 w-9 border border-mercury1 rounded-md outline outline-transparent hover:outline-boston-blue hover:outline-2 [&>svg>path]:hover:!fill-boston-blue',
            activeIconCn: 'outline-boston-blue outline-2 [&>svg>path]:!fill-boston-blue',
          }}
          searchIcon={
            <IconSearch className="mr-2 w-4 h-4 flex-shrink-0 [&>path]:transition-all [&>path]:duration-300" />
          }
          linkCn="flex justify-between items-center no-underline uppercase outline outline-transparent border border-gallery rounded-md p-2 text-cod-gray text-sm default-transition hover:text-boston-blue hover:outline-boston-blue hover:outline-2 [&>svg>path]:hover:!fill-boston-blue"
          skipSession={skipSession}
        />
      </div>
    </header>
  );
};

export default HeaderJC;
