import { ROUTES } from '@/constants/routes/default';
import { getFullYear } from '@/helpers/date';
import Link from 'next/link';

const FooterJC = () => {
  return (
    <footer className="flex md:px-10 max-md:px-4 py-6 min-h-[84px] text-xs text-dove-gray">
      <div className="max-w-[1160px] w-full h-full max-md:block md:flex justify-between items-center m-auto flex-wrap gap-4">
        <div>
          <b>Johnson Jobs</b>
          <p className="mt-1">15001 Kercheval Ave, Grosse Pointe Park, Michigan 48230</p>
        </div>
        <div className="w-fit flex flex-col">
          <Link
            className="font-bold hover:underline md:text-end max-md:mt-1 md:mt-0"
            href={ROUTES.termsOfUse}
          >
            Terms of Use
          </Link>
          <Link className="font-bold hover:underline md:text-end mt-1" href={ROUTES.privacyPolicy}>
            Privacy Policy
          </Link>
          <p className="mt-1">&copy; Johnson Jobs {getFullYear()}, All rights reserved</p>
        </div>
      </div>
    </footer>
  );
};

export default FooterJC;
