import { DBNames } from '@/api/dbs/pg_main';

const PROJECTS_MAP: { [key: string]: DBNames } = {
  canvasnursing: 'cn',
  davidcareers: 'dc',
  kimblegroup: 'kg',
  johnsonjobs: 'jc',
  kochdavisjobs: 'kd',
  mccarthysearch: 'mc',
};

const HOSTS_MAP: { [key: string]: string } = Object.entries(PROJECTS_MAP).reduce(
  (acc, [key, value]) => {
    acc[value] = key;
    return acc;
  },
  {} as { [key: string]: string },
);

export const getProject = (hostname: string): DBNames | null => {
  try {
    if (hostname.includes('localhost') || hostname.includes('jobsearcher')) {
      const match = hostname.match(/\/(cn|dc|jc|kd|kg|mc)\//);
      const segment = match ? (match[1] as DBNames) : null;
      return segment || (process.env.ENV_TO_USE as DBNames) || PROJECTS_MAP['davidcareers'];
    }
    const parts = hostname.split('.');

    const domainName = parts.length > 2 ? parts[parts.length - 2] : parts[0];

    const project =
      PROJECTS_MAP[domainName] ||
      (process.env.ENV_TO_USE as DBNames) ||
      PROJECTS_MAP['davidcareers'];
    return project;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('✌️error from getProject --->', error);
    return 'dc';
  }
};

export const getHostByProject = (prefix: DBNames): string | null => {
  return HOSTS_MAP[prefix] || null;
};
