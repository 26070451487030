import MainErrorDCIcon from '@public/dc/main-error-dc.svg';

const MainErrorDC = () => (
  <div className="flex flex-col items-center justify-center max-md:my-10 md:my-20 px-4">
    <MainErrorDCIcon className="w-[106px] h-[106px] flex-shrink-0" />
    <div className="mt-5 text-cod-gray text-lg font-bold">Hang Tight!</div>
    <div className="mt-3 text-cod-gray max-w-[320px] text-sm text-center">
      We&apos;re making some updates behind the scenes. See you again soon!
    </div>
  </div>
);

export default MainErrorDC;
