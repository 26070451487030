import MainErrorKDIcon from '@public/kd/main-error-kd.svg';

const MainErrorKD = () => (
  <div className="flex flex-col items-center justify-center max-md:my-10 md:my-20 px-4">
    <MainErrorKDIcon className="w-[112px] h-[112px] flex-shrink-0" />
    <div className="mt-8 text-white1 text-xl font-bold">Site Under Maintenance</div>
    <div className="mt-3 text-white1  max-w-[340px] text-center">
      We&apos;re polishing things up to serve you better. Stay tuned!
    </div>
  </div>
);

export default MainErrorKD;
