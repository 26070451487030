import MainErrorJCIcon from '@public/jc/main-error-jc.svg';

const MainErrorJC = () => (
  <div className="flex flex-col items-center justify-center max-md:my-10 md:my-20 px-4">
    <MainErrorJCIcon className="w-[120px] h-[120px] flex-shrink-0" />
    <div className="mt-10 text-cod-gray text-xl font-bold">We&apos;ll Be Back Soon!</div>
    <div className="mt-3 text-cod-gray max-w-[360px] text-center">
      Our site is currently undergoing maintenance. Thank you for your patience
    </div>
  </div>
);

export default MainErrorJC;
