/* eslint-disable @next/next/no-img-element */
import { ROUTES } from '@/constants/routes/default';
import { getFullYear } from '@/helpers/date';
import Link from 'next/link';

const FooterKG = () => {
  return (
    <footer className="flex md:px-10 max-md:px-4 py-7 min-h-[143px] border-t border-shark">
      <div className="text-center text-sm leading-6 m-auto max-w-[470px]">
        Copyright © Kimble Group, {getFullYear()}{' '}
        <Link className="hover:underline" href={ROUTES.termsOfUse}>
          Terms
        </Link>{' '}
        and{' '}
        <Link className="hover:underline" href={ROUTES.privacyPolicy}>
          Privacy Policy
        </Link>{' '}
        Houston | New York | Los Angeles | Seattle | Atlanta
      </div>
    </footer>
  );
};

export default FooterKG;
