/* eslint-disable @next/next/no-img-element */
import { SUPPORT_EMAIL } from '@/constants/dc/default';
import { ROUTES } from '@/constants/routes/default';
import { getFullYear } from '@/helpers/date';
import FooterLogo from '@public/dc/footer-logo.svg';
import Link from 'next/link';

const FooterDC = ({ isAuthorized }: { isAuthorized: boolean }) => {
  return (
    <footer className="flex md:px-10 max-md:px-4 py-7 min-h-[232px] bg-cod-gray">
      <div className="max-w-[1040px] w-full h-full max-md:block md:flex justify-between items-center m-auto flex-wrap gap-4">
        <div className="flex flex-wrap max-md:gap-4 md:gap-16">
          <div>
            <p className="text-vermilion font-bold pb-2">Navigation</p>
            <div className="h-0.5 w-[120px] border-t border-t-black border-b border-b-white4" />
            <Link
              className="block w-fit text-sm text-white hover:underline mt-3"
              href={ROUTES.home}
            >
              Home
            </Link>
            <Link
              className="block w-fit text-sm text-white hover:underline mt-2"
              href={ROUTES.jobs}
            >
              Job Search
            </Link>
            {isAuthorized ? (
              <Link
                className="block w-fit text-sm text-white hover:underline mt-2"
                href={ROUTES.profile}
              >
                Account
              </Link>
            ) : (
              <Link
                className="block w-fit text-sm text-white hover:underline mt-2"
                href={ROUTES.signup}
              >
                Register
              </Link>
            )}
            <Link
              className="block w-fit text-sm text-white hover:underline mt-2"
              href={ROUTES.termsOfUse}
            >
              Terms of Use
            </Link>
            <Link
              className="block text-sm text-white hover:underline mt-2"
              href={ROUTES.privacyPolicy}
            >
              Privacy Policy
            </Link>
          </div>
          <div>
            <p className="text-vermilion font-bold pb-2">Contact Us</p>
            <div className="h-0.5 w-[120px] border-t border-t-black border-b border-b-white4" />
            <p className="font-bold text-sm text-white mt-3">David Careers</p>
            <p className="text-sm text-white">2443 Fillmore St.</p>
            <p className="text-sm text-white">San Francisco, CA 94115</p>
            <p className="text-sm text-white mt-6">
              <span className="font-bold text-vermilion">Phone : </span>
              <a className="hover:underline" href="tel:800-470-8150">
                800-470-8150
              </a>
            </p>
            <p className="text-sm text-white mt-2">
              <span className="font-bold text-vermilion">Email : </span>
              <a className="hover:underline" target="_black" href={`mailto:${SUPPORT_EMAIL}`}>
                {SUPPORT_EMAIL}
              </a>
            </p>
          </div>
        </div>
        <div className="w-fit flex flex-col text-white max-md:mt-3 md:mt-0">
          <FooterLogo className="h-[55px] w-[120px] flex-shrink-0 md:self-end" />
          <p className="text-sm max-md:mt-2 md:mt-20 md:text-end">
            Copyright &copy; {getFullYear()}, David Careers.
          </p>
          <p className="text-sm mt-1 md:text-end">All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default FooterDC;
