/* eslint-disable @next/next/no-head-element */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable @next/next/no-page-custom-font */
import { ReactNode } from 'react';
import Link from 'next/link';

import { Open_Sans, DM_Mono } from 'next/font/google';
import FooterKD from '../FooterKD';
import HeaderLinks from '@/components/HeaderLinks';
import { ROUTES } from '@/constants/routes/default';

import '../../../app/globals.css';
import './DefaultLayoutKD.css';

export const metadata = {
  title: 'Opportunities | Koch Davis',
  description: 'Find the best opportunities at Koch Davis',
};

interface LayoutProps {
  children: ReactNode;
}

const openSans = Open_Sans({
  subsets: ['latin'],
  weight: ['400', '700'],
  display: 'swap',
  variable: '--font-open-sans', // Define a CSS variable for Open Sans
});

const dmMono = DM_Mono({
  subsets: ['latin'],
  weight: ['400', '500'],
  display: 'swap',
  variable: '--font-dm-mono', // Define a CSS variable for DM Mono
});

const DefaultLayoutKD = async ({ children }: LayoutProps) => {
  return (
    <html lang="en" className={`${openSans.variable} ${dmMono.variable}`}>
      <head>
        <meta charSet="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
      </head>
      <body className="body-kd">
        <link rel="shortcut icon" href="/kd/favicon.ico" />
        <div className="h-full flex flex-col justify-between">
          <div>
            <header
              className={
                'flex md:px-10 max-md:px-4 min-h-[140px] bg-bittersweet relative header-background'
              }
            >
              <div className="max-w-[1040px] w-full h-full flex justify-between items-center m-auto flex-wrap gap-4 z-10">
                <Link href={ROUTES.home}>
                  <img
                    width="100"
                    height="100"
                    src="https://assets.kochdavisjobs.com/kd/logo-150.png"
                    alt="Logo"
                  />
                </Link>
                <HeaderLinks
                  listHeaderCn="px-3.5 py-2 text-white3"
                  dropdownClassNames={{
                    listCn:
                      'max-w-[200px] right-0 bg-mine-shaft py-2 rounded-lg border border-white2',
                    elementCn: 'text-sm text-white1 h-9 px-5 flex items-center hover:bg-tundora',
                    rootCn: '',
                    iconCn: 'h-9 w-9 rounded-lg hover:bg-tundora [&>svg>path]:!fill-white',
                    activeIconCn: 'bg-tundora',
                  }}
                  componentCn="flex gap-10 items-center"
                  linkCn="no-underline uppercase text-white1 text-sm hover:translate-y-1"
                  skipSession
                />
              </div>
            </header>
            <main>{children}</main>
          </div>
          <FooterKD isAuthorized={false} />
        </div>
      </body>
    </html>
  );
};

export default DefaultLayoutKD;
