import MainErrorKGIcon from '@public/kg/main-error-kg.svg';

const MainErrorKG = () => (
  <div className="flex flex-col items-center justify-center max-md:my-10 md:my-20 px-4">
    <MainErrorKGIcon className="w-[100px] h-[100px] flex-shrink-0" />
    <div className="mt-8 text-shark font-medium uppercase">Down for Maintenance</div>
    <div className="mt-2 text-shark text-sm max-w-[420px] text-center">
      We&apos;re upgrading our site to serve you better. Thanks for your understanding!
    </div>
  </div>
);

export default MainErrorKG;
