/* eslint-disable @next/next/no-img-element */
import { ROUTES } from '@/constants/routes/default';
import Link from 'next/link';

const FooterKD = ({ isAuthorized }: { isAuthorized: boolean }) => {
  return (
    <footer className="flex md:px-10 max-md:px-4 py-8 bg-mine-shaft">
      <div className="max-w-[1040px] w-full h-full flex flex-wrap justify-between items-center m-auto gap-4">
        <div className="text-sm text-white1">
          <p className="font-bold">Koch Davis</p>
          <p className="mt-2">244 Fifth Avenue, New York, N.Y. 10001</p>
          <a href="tel:800-874-0818" className="block hover:underline mt-2">
            800-874-0818
          </a>
        </div>
        <div className="w-fit flex flex-wrap text-white1 gap-y-2 gap-x-20">
          <ul className="list-disc flex flex-col gap-2 ml-4">
            <li>
              <Link
                className="text-blizzard-blue text-sm font-bold block w-fit hover:underline"
                href={ROUTES.jobs}
              >
                Search Jobs
              </Link>
            </li>
            <li>
              {isAuthorized ? (
                <Link
                  className="text-blizzard-blue text-sm font-bold block w-fit hover:underline"
                  href={ROUTES.profile}
                >
                  Account
                </Link>
              ) : (
                <Link
                  className="text-blizzard-blue text-sm font-bold block w-fit hover:underline"
                  href={ROUTES.signup}
                >
                  Register
                </Link>
              )}
            </li>
          </ul>
          <ul className="list-disc flex flex-col gap-2 ml-4">
            <li>
              <Link
                className="text-blizzard-blue text-sm font-bold block w-fit hover:underline"
                href={ROUTES.termsOfUse}
              >
                Terms of Use
              </Link>
            </li>
            <li>
              <Link
                className="text-blizzard-blue text-sm font-bold block w-fit hover:underline"
                href={ROUTES.privacyPolicy}
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default FooterKD;
