/* eslint-disable @next/next/no-head-element */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable @next/next/no-page-custom-font */
import { ReactNode } from 'react';
import '../../../app/globals.css';
import './DefaultLayoutJC.css';
import { Open_Sans, DM_Mono } from 'next/font/google';

import FooterJC from '../FooterJC';
import HeaderJC from '../HeaderJC';

export const metadata = {
  title: 'Opportunities | Johnson Jobs',
  description: 'Find the best opportunities at Johnson Jobs',
};

interface LayoutProps {
  children: ReactNode;
}

const openSans = Open_Sans({
  subsets: ['latin'],
  weight: ['400', '700'],
  display: 'swap',
  variable: '--font-open-sans', // Define a CSS variable for Open Sans
});

const dmMono = DM_Mono({
  subsets: ['latin'],
  weight: ['400', '500'],
  display: 'swap',
  variable: '--font-dm-mono', // Define a CSS variable for DM Mono
});

const DefaultLayoutJC = async ({ children }: LayoutProps) => {
  return (
    <html lang="en" className={`${openSans.variable} ${dmMono.variable}`}>
      <head>
        <meta charSet="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
      </head>
      <body className="body-jc">
        <link rel="shortcut icon" href="/jc/favicon.ico" />
        <div className="h-full flex flex-col justify-between">
          <div>
            <HeaderJC skipSession />
            <main>{children}</main>
          </div>
          <div className="-z-[1]">
            <div className="prefoot-jc h-80 min-h-80 -mt-24 -z-[1]" />
            <div className="prefoot-shadow-jc h-[60px] min-[60px] -z-[1]" />
          </div>
          <div>
            <FooterJC />
          </div>
        </div>
      </body>
    </html>
  );
};

export default DefaultLayoutJC;
