type TProjectNames =
  | 'jennie-johnson'
  | 'koch-davis'
  | 'kimble-group'
  | 'mccarthy-search'
  | 'david-careers'
  | 'johnson-jobs'
  | 'jennie-johnson'
  | 'canvas-nursing';

const encodedSpace = '%20';
const spaceAndNullRepresenter = '-';
const hyphenAddChar = '-';
const hyphenRepresenter = `${hyphenAddChar}${spaceAndNullRepresenter}`;

export const buildIntroviewUrl = ({
  projectName,
  shortProjectName,
}: {
  projectName: TProjectNames;
  shortProjectName: string;
}) => {
  return `https://introview.com/${projectName}?utm_source=${shortProjectName}&utm_medium=front&utm_campaign=post-a-job`;
};

const prepSlug = (slug: string) =>
  slug
    ? encodeURIComponent(
        slug.replace(/\s+/gm, ' ').replaceAll(spaceAndNullRepresenter, hyphenRepresenter),
      ).replaceAll(encodedSpace, spaceAndNullRepresenter)
    : slug;

export const buildJennieSearchUrl = ({
  state,
  city,
  title,
}: {
  state?: string;
  city?: string;
  title?: string;
}) => {
  const stateQ = state ? prepSlug(state.toLowerCase()) : '-';
  const cityQ = city ? prepSlug(city.toLowerCase()) : '-';
  const titleQ = title ? prepSlug(title) : '-';

  return `https://jenniejohnson.com/jobs/${stateQ}/${cityQ}/-/${titleQ}`;
};
