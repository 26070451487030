/* eslint-disable @next/next/no-head-element */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable @next/next/no-page-custom-font */
import { ReactNode } from 'react';
import '../../../app/globals.css';
import './DefaultLayoutKG.css';
import { Open_Sans, DM_Mono } from 'next/font/google';
import FooterKG from '../FooterKG';
import HeaderLinks from '@/components/HeaderLinks';
import Link from 'next/link';
import { PROJECTS_ROUTES, ROUTES } from '@/constants/routes/default';
import { buildIntroviewUrl } from '@/helpers/routesHelpers';

export const metadata = {
  title: 'Opportunities | Kimble Group',
  description: 'Find the best opportunities at Kimble Group',
};

interface LayoutProps {
  children: ReactNode;
}

const openSans = Open_Sans({
  subsets: ['latin'],
  weight: ['400', '700'],
  display: 'swap',
  variable: '--font-open-sans', // Define a CSS variable for Open Sans
});

const dmMono = DM_Mono({
  subsets: ['latin'],
  weight: ['400', '500'],
  display: 'swap',
  variable: '--font-dm-mono', // Define a CSS variable for DM Mono
});

const DefaultLayoutKG = async ({ children }: LayoutProps) => {
  const introviewUrl = buildIntroviewUrl({
    shortProjectName: PROJECTS_ROUTES.kimbleGroup.shortPath,
    projectName: 'kimble-group',
  });

  return (
    <html lang="en" className={`${openSans.variable} ${dmMono.variable}`}>
      <head>
        <meta charSet="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
      </head>
      <body className="body-kg">
        <link rel="shortcut icon" href="/kg/favicon.ico" />
        <div className="h-full flex flex-col justify-between">
          <div>
            <header className="flex md:px-10 max-md:px-4 min-h-[80px] border-b border-shark">
              <div className="max-w-[1240px] w-full h-full flex justify-between items-center m-auto flex-wrap gap-4">
                <Link href={ROUTES.home}>
                  <img
                    width="144"
                    height="12"
                    src="https://assets.kimblegroup.com/kimblegroup/2024-04/logo_v2.png"
                    alt="Logo"
                  />
                </Link>
                <HeaderLinks
                  introviewUrl={introviewUrl}
                  withCenteredItems
                  listHeaderCn="px-3.5 py-2 text-jumbo"
                  dropdownClassNames={{
                    listCn:
                      'max-w-[200px] right-0 bg-white py-2 border border-shark header-menu-kg',
                    elementCn:
                      'text-sm text-shark h-[34px] px-4 flex items-center hover:bg-athens-gray',
                    rootCn: '',
                    iconCn:
                      'h-10 w-10 border border-mischka hover:border-shark [&>svg>path]:!fill-black',
                    activeIconCn: 'border border-shark',
                    dividerCn: 'mx-4',
                  }}
                  componentCn="flex gap-5 items-center"
                  linkCn="uppercase text-shark text-sm p-2.5 border border-transparent default-transition hover:border-shark"
                  skipSession
                />
              </div>
            </header>
            <main>{children}</main>
          </div>
          <FooterKG />
        </div>
      </body>
    </html>
  );
};

export default DefaultLayoutKG;
