'use client';
// Error boundaries must be Client Components

import { useEffect, useState } from 'react';
import DefaultLayoutDC from '../components/dc/DefaultLayoutDC/DefaultLayoutDC';
import MainErrorDC from '../components/dc/errors/MainErrorDC';
import DefaultLayoutMC from '../components/mc/DefaultLayoutMC/DefaultLayoutMC';
import MainErrorMC from '../components/mc/errors/MainErrorMC';
import DefaultLayoutJC from '../components/jc/DefaultLayoutJC/DefaultLayoutJC';
import MainErrorJC from '../components/jc/errors/MainErrorJC';
import DefaultLayoutKG from '../components/kg/DefaultLayoutKG/DefaultLayoutKG';
import MainErrorKG from '../components/kg/errors/MainErrorKG';
import DefaultLayoutCN from '../components/cn/DefaultLayoutCN/DefaultLayoutCN';
import MainErrorCN from '../components/cn/errors/MainErrorCN';
import DefaultLayoutKD from '../components/kd/DefaultLayoutKD/DefaultLayoutKD';
import MainErrorKD from '../components/kd/errors/MainErrorKD';
import { getProject } from '@/helpers/project';

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const [projectValue, setProject] = useState('');

  // eslint-disable-next-line no-console
  console.log('✌️error --->', error);

  useEffect(() => {
    try {
      const project = getProject(window.location.hostname);
      setProject(project ?? '');
    } catch (e) {
      console.error('Global error: ' + e);
    }
  }, []);

  switch (projectValue) {
    case 'dc':
      return (
        <DefaultLayoutDC>
          <MainErrorDC />
        </DefaultLayoutDC>
      );
    case 'jc':
      return (
        <DefaultLayoutJC>
          <MainErrorJC />
        </DefaultLayoutJC>
      );
    case 'kg':
      return (
        <DefaultLayoutKG>
          <MainErrorKG />
        </DefaultLayoutKG>
      );
    case 'kd':
      return (
        <DefaultLayoutKD>
          <MainErrorKD />
        </DefaultLayoutKD>
      );
    case 'cn':
      return (
        <DefaultLayoutCN>
          <MainErrorCN />
        </DefaultLayoutCN>
      );
    case 'mc':
      return (
        <DefaultLayoutMC>
          <MainErrorMC />
        </DefaultLayoutMC>
      );
    default:
      return (
        <html>
          <body>
            <h2>Something went wrong!</h2>
            <button onClick={() => reset()}>Try again</button>
          </body>
        </html>
      );
  }

  // return (
  //   <html>
  //     <body>
  //       <h2>Something went wrong!</h2>
  //       <button onClick={() => reset()}>Try again</button>
  //     </body>
  //   </html>
  // );
}
